/* You can add global styles to this file, and also import other style files */

$dark_blue: #141D2F;
$blue: #4B47FF;
$light_blue: #00BEE8;
$dark_grey: #414856;

$padding_side: 100px;
$padding_side_double: 200px;
// $padding_side_bigscreen: 20vw;
$padding_side_bigscreen: calc((100vw - 1200px) / 2);

$mobile-breakpoint: 400px;
$tablet-breakpoint: 1000px;
$wide-breakpoint: 1250px;
$bigscreen-breakpoint: 1920px;

html {
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: $dark_blue;
}


/* poppins-100 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/poppins-v20-latin-100.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-200 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/poppins-v20-latin-200.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/poppins-v20-latin-300.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/poppins-v20-latin-regular.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/poppins-v20-latin-700.woff2') format('woff2');
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

h2 {
    font-size: 90px;
    white-space: nowrap;
}

h3 {
    font-size: 32px;
}

h2,
h3 {
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
    color: white;
}

.btn {
    padding: 15px 30px 15px 30px;
    border-radius: 10px;
    font-size: 23px;
    color: white;
    box-shadow: 4px 4px 4px 0px #00000040;
    display: block;
    max-width: fit-content;
    border: 0;
    transition: all 275ms ease-in-out;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
}

.btn-primary {
    background-color: $light_blue;
    transition: all 275ms ease-in-out;

    &:hover {
        background-color: $blue;
    }
}

.btn-project {
    background-color: $blue;
    border: 1px solid $blue;

    &:hover {
        border: 1px solid $light_blue;
        background-color: $light_blue;
    }
}

.btn-git {
    background-color: $dark_blue;
    border: 1px solid $light_blue;

    &:hover {
        background-color: $light_blue;
    }
}

.content-text {
    font-size: 16px;
    color: white;
    margin-block-start: 0;
    margin-block-end: 0;
}

.aligned-left {
    text-align: left;
}

.aligned-right {
    text-align: right;
}

.aligned-center {
    text-align: center;
}

.horicontal-lign {
    height: 4px;
    border: 0;
    background-color: $blue;
}

.arrow-up {
    position: absolute;
    right: 100px;
    bottom: 50px;

    img{
        height: 39px;
        width: 39px;
    }

    &:hover {
        cursor: pointer;
    }
}

@media (min-width: $tablet-breakpoint) {
    
    .desktop-hidden {
        display: none;
    }

}

@media (max-width: $tablet-breakpoint) {

    .mobile-hidden {
        display: none !important;
    }

    h2 {
        font-size: 70px;
    }

    
    .arrow-up {
        right: calc($padding_side / 2);
    }

}

@media (max-width: $mobile-breakpoint) {

    h2 {
        font-size: 45px;
    }

    h3 {
        font-size: 23px;
    }

    .arrow-up {
        right: calc($padding_side / 4);
    }

}

@media (min-width: $bigscreen-breakpoint) {

    .arrow-up {
        right: $padding_side_bigscreen;
    }
}